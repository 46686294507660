import { Button, Illustration, Row, RowList, Stack, Title } from '@uala/abra';
import GenericForm from '../../components/GenericForm';
import useDesktopSize, { useGetDevice } from '../../hooks/useDesktopSize';
import { iconsTypes } from '@uala/abra/dist/src/icons';
import { createAmplitudeEvent } from '../../contexts/AmplitudeProvider';
import { PERSON_TYPE } from '../../shared/constants';
import { useNavigate } from 'react-router-dom';

const data = [
  {
    id: 1,
    description: 'Con esta CLABE podrás regresar para completar el registro.',
    icon: 'atm-pin'
  },
  {
    id: 2,
    description:
      'Con Ualá, tu dinero está protegido porque es un producto operado por ABC Capital, SA, Institución de Banca Múltiple.',
    icon: 'cbu'
  }
];

const WithoutClabe: React.FC = () => {
  const navigate = useNavigate();
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  const device = useGetDevice();
  const personType =
    window.sessionStorage.getItem('personType') || PERSON_TYPE.PPFF;

  const handleClick = () => {
    createAmplitudeEvent('registro_cobros_selecciona_crear_cuenta_uala', {
      device,
      user_type: personType
    });
    window.location.href = 'https://www.uala.mx/';
  };

  return (
    <GenericForm
      form={{
        id: '1',
        name: 'without-clabe',
        illustration: 'business-data'
      }}
      loading={false}
      showLogo={isDesktop}
      illustrationPosition={isDesktop ? 'side' : undefined}
    >
      <Stack
        align="center"
        direction="column"
        spacing={16}
        css={{ maxWidth: '320px', margin: '20px auto' }}
      >
        <Illustration name="large-tarjeta-credito-fisica" />

        <Title>Abre tu cuenta gratuita en Ualá para tener una CLABE</Title>

        <RowList>
          {data.map((row) => (
            <Row
              description={row.description}
              icon={row.icon as iconsTypes}
              id={row.icon}
            />
          ))}
        </RowList>

        <Button onClick={handleClick}>Crear cuenta</Button>

        <Button
          onClick={() => {
            navigate('/onboarding?currentStep=3');
          }}
          variant="text"
        >
          Volver
        </Button>
      </Stack>
    </GenericForm>
  );
};

export default WithoutClabe;
