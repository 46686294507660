import { Stack, Title, Text, Button, CardHorizontal, Alert } from '@uala/abra';
import useDesktopSize, { useGetDevice } from '../../hooks/useDesktopSize';
import GenericForm from '../../components/GenericForm';
import { handleGeolocalization } from '../../utils/localization';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { PERSON_TYPE } from '../../shared/constants';
import { createAmplitudeEvent } from '../../contexts/AmplitudeProvider';
import CardOptions from './CardOptions';

const GeoDenied: React.FC = () => {
  const [option, setOption] = useState<'phone' | 'desktop' | ''>('');
  const [geoError, setGeoError] = useState(false);
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  const navigate = useNavigate();
  const device = useGetDevice();
  const personType =
    window.sessionStorage.getItem('personType') || PERSON_TYPE.PPFF;

  useEffect(() => {
    createAmplitudeEvent('registro_cobros_no_permite_acceso_geolocalizacion', {
      device,
      user_type: personType
    });
  }, []);

  const handleClick = async () => {
    try {
      const res = await handleGeolocalization(false, device, personType);
      if (res) {
        window.sessionStorage.setItem('cordinates', JSON.stringify(res));
        navigate('/onboarding?currentStep=5');
      }
    } catch (error) {
      setGeoError(true);
    }
  };

  return (
    <GenericForm
      form={{
        id: '1',
        name: 'geolocation-denied',
        illustration: 'create-account'
      }}
      loading={false}
      showLogo={isDesktop}
      illustrationPosition={isDesktop ? 'side' : undefined}
    >
      <>
        <Stack
          direction="column"
          align="center"
          justify="space-between"
          css={{ textAlign: 'center', height: '100%' }}
        >
          {option ? (
            <CardOptions option={option} geoError={geoError} />
          ) : (
            <Stack
              direction="column"
              justify="center"
              spacing="20"
              css={{ textWrap: 'balance', marginTop: '30px' }}
            >
              {geoError && (
                <Alert
                  variant="error"
                  description="Para avanzar,  tienes que seguir los pasos indicados."
                  css={{ marginBottom: '20px' }}
                />
              )}

              <Title>Debes activar el permiso de ubicación </Title>

              <Text>Selecciona tu dispositivo y sigue las indicaciones:</Text>

              <CardHorizontal
                title="Celular"
                onClick={() => {
                  setOption('phone');
                  setGeoError(false);
                  createAmplitudeEvent(
                    'registro_cobros_selecciona_dispositivo_geolocalizacion',
                    {
                      device,
                      user_type: personType,
                      dispositivo: 'phone'
                    }
                  );
                }}
              />

              <CardHorizontal
                title="Computadora"
                onClick={() => {
                  setOption('desktop');
                  setGeoError(false);
                  createAmplitudeEvent(
                    'registro_cobros_selecciona_dispositivo_geolocalizacion',
                    {
                      device,
                      user_type: personType,
                      dispositivo: 'computer'
                    }
                  );
                }}
              />
            </Stack>
          )}

          <Stack direction="column" css={{ marginTop: '20px' }}>
            <Button onClick={handleClick}>Ya lo hice</Button>

            <Button
              onClick={() => {
                if (option === '') {
                  navigate('/onboarding?currentStep=4');
                }

                setOption('');
                setGeoError(false);
              }}
              variant="text"
            >
              Volver
            </Button>
          </Stack>
        </Stack>
      </>
    </GenericForm>
  );
};

export default GeoDenied;
