import { useEffect, useRef, ReactNode } from 'react';
import { Stack } from '@uala/abra';
import { Button, Dialog } from './styles';

export const Modal = ({
  openModal,
  handleModal,
  children
}: {
  openModal: boolean;
  handleModal: () => void;
  children: ReactNode;
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const dialog = dialogRef.current;
    if (!dialog) return;

    openModal ? dialog.showModal() : dialog.close();
  }, [openModal]);

  return (
    <Dialog ref={dialogRef}>
      <Stack direction="column">{children}</Stack>
      <Stack
        justify="end"
        align="center"
        css={{
          marginTop: '20px',
          borderTop: '1px solid #bdbdbd'
        }}
      >
        <Stack css={{ paddingTop: '20px' }}>
          <Button onClick={handleModal}>Entendido</Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};
