import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserDataContext } from '../../contexts/UserDataProvider';
import { useSnackbar } from '@uala/ui';
import { getErrorMessageByType, loadActivities, loadCities } from './utils';
import dataPPFF from './data/onboardingFormPPFF.json';
import dataPPMM from './data/onboardingFormPPMM.json';
import dataPFAE from './data/onboardingFormPFAE.json';
import { loadOptions } from '../../components/GenericForm/utils';
import {
  CustomValidationProps,
  Cordinates
} from '../../components/GenericForm/types';
import GenericForm from '../../components/GenericForm';
import useDesktopSize, { useGetDevice } from '../../hooks/useDesktopSize';
import validations from './customValidations';
import { OnboardingData, postOnboarding } from '../../services/onboarding';
import { checkResponseStatus } from '../../services/utils';
import { createAmplitudeEvent } from '../../contexts/AmplitudeProvider';
import { PERSON_TYPE } from '../../shared/constants';
import { handleGeolocalization } from '../../utils/localization';

const Onboarding: React.FC = () => {
  const userData = useContext(UserDataContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const addSnackbar = useSnackbar();
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  const device = useGetDevice();
  const personType =
    window.sessionStorage.getItem('personType') || PERSON_TYPE.PPFF;
  const onboardingForms = {
    [PERSON_TYPE.PPFF]: dataPPFF,
    [PERSON_TYPE.PPMM]: dataPPMM,
    [PERSON_TYPE.PROFESSIONAL]: dataPFAE
  };

  const currentData = onboardingForms[personType];
  const [previousZipCode, setPreviousZipCode] = useState<string | null>(null);

  const eventsBYPersonType = new Map([
    [
      PERSON_TYPE.PPFF,
      [
        'registro_cobros_completa_datos_personales',
        'registro_cobros_completa_datos_comerciales',
        'registro_cobros_completa_datos_bancarios',
        'registro_cobros_pedido_geolocalizacion',
        'registro_cobros_completa_direccion'
      ]
    ],
    [
      PERSON_TYPE.PROFESSIONAL,
      [
        'registro_cobros_completa_datos_personales',
        'registro_cobros_completa_datos_comerciales',
        'registro_cobros_completa_datos_bancarios',
        'registro_cobros_pedido_geolocalizacion',
        'registro_cobros_completa_direccion'
      ]
    ],
    [
      PERSON_TYPE.PPMM,
      [
        'registro_cobros_completa_datos_comerciales',
        'registro_cobros_completa_direccion',
        'registro_cobros_completa_datos_bancarios',
        'registro_cobros_completa_datos_personales'
      ]
    ]
  ]);
  const eventsByStep = eventsBYPersonType.get(personType) as string[];

  if (personType === PERSON_TYPE.PPMM) {
    window.sessionStorage.setItem(
      'formValues',
      JSON.stringify({
        countryOfRegister: 'México',
        ownerCountryOfBirth: 'México'
      })
    );
  }

  const onSubmit = async (data: OnboardingData) => {
    try {
      setLoading(true);
      createAmplitudeEvent(eventsByStep.at(-1) as string, {
        device,
        user_type: personType
      });

      const cordinates: Cordinates = JSON.parse(
        window.sessionStorage.getItem('cordinates')!
      );

      const response: any = await postOnboarding(
        { ...data, personType },
        userData?.accountEmail as string,
        userData?.accessToken as string,
        cordinates?.latitude,
        cordinates?.longitude
      );
      checkResponseStatus(response);
      navigate('/pending-approval');
    } catch (error: any) {
      const errorMessage = getErrorMessageByType(error.response?.data);
      addSnackbar(errorMessage);
      setLoading(false);
      createAmplitudeEvent('registro_cobros_error_api', {
        device,
        tipo_error: 'fin_registro',
        user_type: personType
      });
    }
  };

  const partialSubmit = async (_: any, activeStep: number) => {
    createAmplitudeEvent(eventsByStep[activeStep - 1], {
      device,
      user_type: personType
    });

    if (activeStep === 4) {
      try {
        const data = await handleGeolocalization(true, device, personType);
        if (data) {
          window.sessionStorage.setItem('cordinates', JSON.stringify(data));
          return true;
        }
      } catch (error) {
        navigate('/geolocation-denied');
      }

      return false;
    }

    return true;
  };

  useEffect(() => {
    loadActivities(currentData);
  }, [currentData]);

  const handleCustomValidations = async ({
    input,
    methods
  }: CustomValidationProps) => {
    switch (input.name) {
      case 'birthday':
        validations.birthday({ input });
        break;
      case 'formationDate':
        validations.formationDate({ input });
        break;

      case 'countryOfRegister':
        validations.countryOfRegister(
          { input },
          'Aún no operamos en el país seleccionado.'
        );
        break;
      case 'ownerCountryOfBirth':
        validations.countryOfRegister(
          { input },
          'La persona apoderada debe ser de México.'
        );
        break;

      case 'federalEntityOfBirth':
        validations.federalEntityOfBirth({ input, methods });
        break;

      case 'phoneNumber':
        validations.phoneNumber({ input });
        break;

      case 'clabe':
        validations.clabe({ input, methods });
        break;

      case 'provinces':
        if (!methods) return;

        const postalCode = methods?.getValues('zipCode');
        if (!postalCode) return;

        if (
          methods.getValues('suburbs')?.length > 0 &&
          postalCode === previousZipCode
        )
          return;

        const suburbsOptions = await loadCities(postalCode, methods);

        if (!suburbsOptions) {
          methods.setValue('provinces', '');
          methods.setValue('cities', '');

          if (methods.getValues('suburbs') !== '') {
            methods.setValue('suburbs', '');
          }

          loadOptions(currentData.steps, 'suburbs', []);

          if (!methods.formState.errors.zipCode) {
            methods.setError('zipCode', {
              type: 'manual',
              message: 'Código postal inexistente.'
            });

            return;
          }

          return;
        }

        if (suburbsOptions) {
          loadOptions(currentData.steps, 'suburbs', suburbsOptions);
          methods.setValue('suburbs', suburbsOptions, { shouldValidate: true });
        }

        setPreviousZipCode(postalCode);
        break;

      case 'rfc':
        validations.rfc({ input, methods });
        break;

      default:
        break;
    }
  };

  const handleExtraClick = () => {
    createAmplitudeEvent('registro_cobros_selecciona_no_tengo_clabe', {
      device,
      user_type: personType
    });
    navigate('/no-clabe');
  };

  return (
    <GenericForm
      form={currentData.steps}
      onSubmit={onSubmit}
      partialSubmit={partialSubmit}
      loading={loading}
      showLogo={isDesktop}
      showBackButton
      showStepProgress
      illustrationPosition={isDesktop ? 'side' : undefined}
      handleCustomValidations={handleCustomValidations}
      handleExtraClick={handleExtraClick}
    />
  );
};

export default Onboarding;
