import { ERROR_CODES } from './errorsCodes';
import { Option } from '../../components/GenericForm/Controls/Select/types';
import { Activity } from './types';
import { loadOptions } from '../../components/GenericForm/utils';
import activities from './data/activities.json';
import { Mask } from 'react-text-mask';
import { UseFormReturn } from 'react-hook-form';

export const getErrorMessageByType = (error: any) => {
  let message: string = '';
  message = ERROR_CODES[error.code];

  if (!message) {
    return 'Algo salió mal, por favor intente de nuevo.';
  }
  return message;
};

export const loadActivities = (data: any) => {
  let activitiesList: Option[] = [];

  activities.forEach((activity: Activity) => {
    activitiesList.push({
      label: activity.name,
      value: String(activity.MCC)
    });
  });

  loadOptions(data.steps, 'activity', activitiesList);
};

type PostalCodeIndex = { [postalCode: string]: string[] };

interface Neighborhood {
  CP: string;
  COLONIA: string;
  MUNICIPIO: string;
}

export const loadCities = async (
  postalCode: string,
  methods: UseFormReturn
) => {
  const { default: postalCodeIndex }: { default: PostalCodeIndex } =
    await import('./data/locations/estados.json');

  let stateFound: string | null = null;

  for (const [state, postalCodes] of Object.entries(postalCodeIndex)) {
    if (postalCodes.includes(postalCode)) {
      stateFound = state;
      break;
    }
  }

  if (!stateFound) return;

  const { default: jsonData }: { default: Neighborhood[] } = await import(
    `./data/locations/json/${stateFound}.json`
  );

  const result = jsonData.filter((item) => item.CP === postalCode);

  if (result.length > 0) {
    const municipality = result[0].MUNICIPIO;
    const neighborhoods = Array.from(
      new Set(result.map((item: Neighborhood) => item.COLONIA))
    );

    methods.setValue('provinces', stateFound);
    methods.setValue('cities', municipality);

    return neighborhoods.map((neighborhood) => ({
      label: neighborhood,
      value: neighborhood
    }));
  }
};

const dateMask: Mask = [
  /[0-3]/,
  /\d/,
  '/',
  /[0,1]/,
  /\d/,
  '/',
  /[1-2]/,
  /[0,9]/,
  /\d/,
  /\d/
];

const phoneNumberMask: Mask = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const clabeInterbancariaMask: Mask = [
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/
];

const rfcMask: Mask = [
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-1]/,
  /[0-9]/,
  /[0-3]/,
  /[0-9]/,
  ' ',
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/
];

const pfaeRFC: Mask = [
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  /[a-zA-Z]/,
  ' ',
  /[0-9]/,
  /[0-9]/,
  /[0-1]/,
  /[0-9]/,
  /[0-3]/,
  /[0-9]/,
  ' ',
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/
];

const zipCodeMask: Mask = [/\d/, /\d/, /\d/, /\d/, /\d/];

export const getMask: (inputName: string) => Mask = (inputName: string) => {
  switch (inputName) {
    case 'birthday':
    case 'formationDate':
      return dateMask;
    case 'phoneNumber':
      return phoneNumberMask;
    case 'clabe':
      return clabeInterbancariaMask;
    case 'zipCode':
      return zipCodeMask;
    case 'rfc':
      return rfcMask;
    case 'pfaeRFC':
      return pfaeRFC;
    default:
      return [''];
  }
};
