import { InputProps } from '@uala/ui/Input/types';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import {
  color,
  display,
  maxWidth,
  minWidth,
  space,
  width
} from 'styled-system';

export const commonFieldStyles = `
  background: transparent;
  border: none;
  font-family: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  position: relative;
  width: 100%;
  z-index: 1;
  &::placeholder {
    color: transparent;
  }
  &:-webkit-autofill ~ label {
    transition: none;
  }
  &:placeholder-shown ~ label,
  &:placeholder-shown:-webkit-autofill ~ label {
    font-size: 1rem;
    cursor: text;
    top: 20px;
    color: #3a3a3a;
  }
  &:required,
  &:invalid {
    box-shadow: none;
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: color 9999s ease-out, background-color 9999s ease-out;
    transition-delay: 9999s;

    &:first-line {
      font-size: 1rem;
      font-family: 'Public Sans', sans-serif;
    }
  }

  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

export const CustomMaskedInput = styled(MaskedInput)`
  ${commonFieldStyles};
  transition: border-color 0.2s ease-in-out;
  font-size: 1rem;
  line-height: 1.5;
  color: #3a3a3a;
`;

export const Addon = styled.span`
  display: block;
  padding-left: 0.5rem;
  z-index: 2;
`;

export const HelperText = styled.span`
  align-self: flex-start;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: normal;
`;

export const Label = styled.label`
  position: absolute;
  font-size: 1rem;
  cursor: text;
  top: 20px;
  color: #3a3a3a;
`;

export const Container = styled.div`
  display: flex;
  border: 1px;
  border-bottom-style: solid;
  border-radius: 0;
  position: relative;
  margin-bottom: 1rem;
  transition: border-color 0.2s;
`;

export const Wrapper = styled.div<InputProps>`
  position: relative;
  width: 100%;

  & button {
    position: absolute;
    font-size: 0.875rem;
    color: #3564fd;
    right: 0;
    top: 21px;
    border: none;
    background: transparent;
  }

  & ${Container} {
    padding: ${({ multiline }) => (multiline ? '0' : '1.25rem 0 0.25rem')};
    border-color: ${({ theme, error, focused }) =>
      error
        ? theme.colors.red[40]
        : focused
        ? theme.colors.blue[50]
        : theme.colors.grey[50]};
    ${({ multiline }) => multiline && 'border: none'};
  }

  & ${Addon} {
    font-size: 1rem;
    color: ${({ theme, error, focused }) =>
      error
        ? theme.colors.red[70]
        : focused
        ? theme.colors.blue[50]
        : theme.colors.grey[80]};
  }

  & ${Label} {
    color: ${({ theme, error }) =>
      error ? theme.colors.red[70] : theme.colors.grey[80]};
    &.has-value {
      position: absolute;
      top: 0;
      display: block;
      transition: 0.2s;
      font-size: 0.75rem;
      line-height: 1.33;
      color: ${({ theme, error }) =>
        error ? theme.colors.red[70] : theme.colors.blue[50]};
    }
  }

  & ${HelperText} {
    color: ${({ theme, error }) =>
      error ? theme.colors.red[70] : theme.colors.grey[90]};
  }

  & ${CustomMaskedInput} {
    ${({ multiline }) => multiline && 'min-height: 1.5rem'};

    &:focus {
      border-color: ${({ theme, error }) =>
        error ? theme.colors.red[40] : theme.colors.blue[50]};

      & ~ ${Label} {
        position: absolute;
        top: 0;
        display: block;
        transition: 0.2s;
        font-size: 0.75rem;
        line-height: 1.33;
        color: ${({ theme, error }) =>
          error ? theme.colors.red[70] : theme.colors.blue[50]};
      }

      & ~ ${Addon} {
        color: ${({ theme }) => theme.colors.blue[50]};
      }
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.grey[50]};

      & ~ ${Label}, & ~ ${HelperText} {
        color: ${({ theme }) => theme.colors.grey[50]};
      }

      & ~ ${Addon} {
        color: ${({ theme }) => theme.colors.grey[50]};
      }

      &:-webkit-autofill:first-line {
        color: ${({ theme }) => theme.colors.grey[50]};
      }
    }
  }

  ${space}
  ${color}
  ${display}
  ${width}
  ${maxWidth}
  ${minWidth}
`;

export const WrapperOutlined = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  padding-top: 0;

  & ${CustomMaskedInput} {
    background: #fff;
    color: ${({ theme }) => theme.colors.grey[80]};
    order: 2;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme, error }) =>
      error ? theme.colors.red[70] : theme.colors.grey[30]};
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 18px;
    margin-bottom: 8px;

    &:disabled::placeholder {
      color: ${({ theme }) => theme.colors.grey[50]};
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.grey[80]};
    }

    &:focus {
      border-color: ${({ theme }) => theme.active};
      padding-bottom: 12px;

      &::placeholder {
        color: transparent;
      }

      & ~ ${Label} {
        display: none;
      }
    }
  }

  & textarea${CustomMaskedInput} {
    padding: 24px;

    &:focus {
      padding-bottom: 24px;
    }
  }

  & ${Label} {
    display: none;
  }

  & ${HelperText} {
    order: 3;
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  content: '';
  top: -5px;
  right: 0;
  z-index: 20;
`;

export const CustomButton = styled.button`
  cursor: pointer;
`;
