import { Alert, Spacer, Stack, Text } from '@uala/abra';

const CardOptions = ({
  option,
  geoError
}: {
  option: string;
  geoError: boolean;
}) => {
  return (
    <Stack
      direction="column"
      align="start"
      css={{ marginTop: '20px', textAlign: 'left' }}
    >
      {geoError && (
        <Alert
          variant="error"
          description="Para avanzar,  tienes que seguir los pasos indicados."
          css={{ margin: '0 auto 30px auto' }}
        />
      )}

      {option === 'phone' ? (
        <>
          <Text weight="bold">IOS</Text>
          <Text size="sm">
            1. Abre el menú de ajustes y dirígete a "Privacidad y seguridad".
          </Text>
          <Text size="sm">
            2. Selecciona "Ubicación" y busca la configuración de tu navegador
            (por ejemplo, Chrome).
          </Text>
          <Text size="sm">
            3. Asegúrate de que la opción "Preguntar la próxima vez" esté
            activada para permitir el acceso a la ubicación.
          </Text>

          <Spacer />

          <Text weight="bold">Android</Text>
          <Text size="sm">
            1. Abre un navegador y ve a los tres puntos en la esquina superior
            derecha.
          </Text>
          <Text size="sm">
            2. Selecciona la “i” de "Información" y busca la sección de
            "Permisos".
          </Text>
          <Text size="sm">
            3. Allí, elige la opción "Restablecer permisos" y selecciónala.
          </Text>
        </>
      ) : (
        <>
          <Text weight="bold">Computadora</Text>
          <Text size="sm">
            1. En la barra del navegador, busca el ícono de ubicación
            (generalmente se parece a un pequeño pin o punto) y haz clic allí.
          </Text>
          <Text size="sm">
            2. Selecciona la opción "Siempre permitir que Ualá acceda a tu
            ubicación" para habilitar el acceso.
          </Text>
        </>
      )}
    </Stack>
  );
};

export default CardOptions;
