import React, { useEffect, useRef, useState } from 'react';
import { omit, pick } from '@styled-system/props';
import { useFormContext } from 'react-hook-form';
import { ControlElement } from '../../types';
import { SelectProps } from './types';
import {
  StyledOutlinedSelect,
  StyledSelect,
  StyledSelectBase,
  StyledDropdown,
  StyledSearchInput,
  StyledOption,
  StyledSelectedValue
} from './styles';

const selectVariants = {
  standard: StyledSelect,
  outlined: StyledOutlinedSelect
};

const SelectElement = React.forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      id,
      label,
      rightAddon,
      form_variant = 'standard',
      options,
      name = '',
      onChange,
      onBlur,
      value,
      handleSuperChange,
      ...rest
    },
    ref
  ) => {
    const wrapperProps = pick(rest);
    const inputProps = omit(rest);

    const [searchTerm, setSearchTerm] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const handleClickOutside = (e: MouseEvent) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(e.target as Node)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () =>
        document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const SelectComponent = selectVariants[form_variant];
    const methods = useFormContext();
    const {
      formState: { errors, touchedFields },
      setValue,
      watch
    } = methods;
    const currentValue = watch(name);

    const handleSelect = (selectedValue: string) => {
      setValue(name, selectedValue);
      setIsOpen(false);
      if (handleSuperChange) {
        const selectedOption = options.find((o) => o.value === selectedValue);
        handleSuperChange(selectedOption?.id || selectedOption?.value, methods);
      }

      const event = {
        target: {
          name,
          value: selectedValue
        }
      } as React.ChangeEvent<HTMLInputElement>;

      onChange && onChange(event);
    };

    const error: boolean = errors[name] && touchedFields[name];

    return (
      <SelectComponent {...wrapperProps} error={Boolean(error)}>
        <div onClick={() => setIsOpen(!isOpen)} {...inputProps}>
          <StyledSelectBase>
            <StyledSelectedValue className={currentValue ? 'has-value' : ''}>
              {options.find((option) => option.value === currentValue)?.label ||
                label}
            </StyledSelectedValue>
          </StyledSelectBase>
        </div>

        {isOpen && (
          <StyledDropdown ref={dropdownRef}>
            <StyledSearchInput
              type="text"
              placeholder={`Buscar ${label}`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClick={(e) => e.stopPropagation()}
            />
            {filteredOptions.map((option, index) => (
              <StyledOption
                key={`${name}-${index}`}
                onClick={() => handleSelect(option.value)}
              >
                {option.label}
              </StyledOption>
            ))}
          </StyledDropdown>
        )}
        <label
          htmlFor={name}
          className={currentValue && currentValue !== '' ? 'has-value' : ''}
        >
          {currentValue ? label : ''}
        </label>
        {error && (
          <span>
            <>{errors[name]!.message}</>
          </span>
        )}
      </SelectComponent>
    );
  }
);

export const Select: React.FC<ControlElement> = (
  { name, placeholder, options = [], validations, handleSuperChange },
  props: any
) => {
  const { register } = useFormContext();
  const sortedOptions = options.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
  return (
    <SelectElement
      {...register(name, validations)}
      label={placeholder}
      options={sortedOptions}
      handleSuperChange={handleSuperChange}
      {...props}
    />
  );
};
