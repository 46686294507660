import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  outline: none;
  color: #3a3a3a;
  background-color: unset;
  border: none;
  font-size: 16px;
  padding: 0;
`;

export const Dialog = styled.dialog`
  border: none;
  border-radius: 10px;
  max-width: 85%;
`;
